exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-abstract-js": () => import("./../../../src/pages/abstract.js" /* webpackChunkName: "component---src-pages-abstract-js" */),
  "component---src-pages-associate-fellow-course-js": () => import("./../../../src/pages/associateFellowCourse.js" /* webpackChunkName: "component---src-pages-associate-fellow-course-js" */),
  "component---src-pages-conference-js": () => import("./../../../src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-first-aid-course-js": () => import("./../../../src/pages/firstAidCourse.js" /* webpackChunkName: "component---src-pages-first-aid-course-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-guest-faculty-js": () => import("./../../../src/pages/guestFaculty.js" /* webpackChunkName: "component---src-pages-guest-faculty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-regular-training-js": () => import("./../../../src/pages/regularTraining.js" /* webpackChunkName: "component---src-pages-regular-training-js" */),
  "component---src-pages-reseach-publications-js": () => import("./../../../src/pages/reseach-publications.js" /* webpackChunkName: "component---src-pages-reseach-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-secretary-js": () => import("./../../../src/pages/secretary.js" /* webpackChunkName: "component---src-pages-secretary-js" */),
  "component---src-pages-seminars-js": () => import("./../../../src/pages/seminars.js" /* webpackChunkName: "component---src-pages-seminars-js" */),
  "component---src-pages-spirometry-audiometry-js": () => import("./../../../src/pages/spirometry_audiometry.js" /* webpackChunkName: "component---src-pages-spirometry-audiometry-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

